<template>
  <div class="main-conent main-conent-minheight">
    <el-form label-position="right" :model="formData" label-width="130px" v-loading.lock="loading" :rules="formRule" ref="editForm" class="demo-ruleForm">
      <el-card>
        <template #header>微信授权配置<span class="CardHeaderDesc">提示：更改此配置将可能导致系统不可用</span></template>
        <el-form-item label="APPID" prop="appid">
          <el-input v-model="formData.appId"></el-input>
        </el-form-item>
        <el-form-item label="APPSecret" prop="appSecret">
          <el-input v-model="formData.appSecret"></el-input>
        </el-form-item>
        <el-form-item label="支付APISecret" prop="apiSecret">
          <el-input v-model="formData.apiSecret"></el-input>
        </el-form-item>
        <el-form-item label="支付API商户号" prop="apiSecret">
          <el-input v-model="formData.apiMchId"></el-input>
        </el-form-item>

      </el-card>

      <el-card shadow="never" class="">
        <el-button type="primary" @click="Submit('editForm')" v-loading.lock="loading">
          提交
        </el-button>
        <el-button @click="RefreshPage">
          刷新
        </el-button>
      </el-card>
    </el-form>
  </div>
</template>
<script>
import { queryActivitySetting, editActivitySetting } from '@/service/activity.js';
import { activityFormRule } from '@/utlis/rules/activityRule.js';
import ActivityProcuctEditer from '@/components/Table/ActivityProcuctEditer.vue';
export default {

  data () {
    return {
      activeId: 0,
      formRule: activityFormRule,
      formData: {
        id: 0,
        appid: '',
        appSecret: '',
        apiSecret: '',
        apiMchId: '',
      },
      loading: false,
    }
  },
  components: { ActivityProcuctEditer },
  created () {
    this.activeId = this.$route.query.activeId;
    this.DataBinding();
  },
  methods: {
    //重置、刷新
    RefreshPage () {
      this.DataBinding();
    },
    //加载数据
    DataBinding () {
      if (this.activeId && this.activeId > 0) {
        this.loading = true;
        queryActivitySetting(this.activeId).then(res => {
          this.formData = res.data;
          this.loading = false;
        }).catch(ex => {
          this.loading = false;
          this.$notify({
            title: '警告',
            message: '当前活动没有数据可供查询',
            type: 'warning'
          });
          this.$route.go(-1);
        })
      }
    },
    //提交表单
    Submit (formName) {
      this.loading = true;
      //表单数据验证
      this.$refs[formName].validate(valid => {
        if (valid) {
          editActivitySetting(this.formData).then(res => {
            if (res.code == 0) {
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.$router.go(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
      this.loading = false;
    }
  }
}
</script>
<style scoped lang="scss">
.CardHeaderDesc {
  font-size: 12px;
  color: gray;
  margin-left: 20px;
}
</style>